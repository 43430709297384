import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";

function ProblemScapeCh3Page() {
  return (
    <>
      <ProblemScapeGameHeader />
      <section
        id="chapter-3-overview"
        className="Index-page"
        data-collection-id="6063b22b116f53481ae583f4"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-content "
          id="yui_3_17_2_1_1671304180297_3021"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-6063b22b116f53481ae583f4"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671304180297_3020">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671304180297_3019"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-4a072e1b1a06ae7c06f1"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      CHAPTER 3 OVERVIEW: <br />
                      THE SHADY SHOPKEEPER
                    </h2>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_3018"
                >
                  <div
                    className="col sqs-col-7 span-7"
                    id="yui_3_17_2_1_1671304180297_3017"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1658166476296_6635"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3016"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_3015"
                        >
                          <StaticImage
                            src="../../images/problemscape/house.png"
                            alt=""
                            height={440}
                            objectFit="cover"
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-5 span-5"
                    id="yui_3_17_2_1_1671304180297_3042"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="107.51708428246015"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1658170341404_6328"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3041"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                        >
                          <StaticImage
                            src="../../images/standards/chapter-3.png"
                            alt="Chapter 3"
                            height={440}
                            objectFit="cover"
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-2e1bb0de2424f0c7f5f2"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      <strong>
                        Could the paintball shopkeeper be an impostor?
                      </strong>
                    </h3>
                    <ul data-rte-list="default">
                      <li>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          The player needs to get some information from the
                          paintball shopkeeper, but he refuses to tell them
                          anything unless they pay 40 rubies and play a game of
                          paintball in the arena behind the shop! Also, does the
                          player know the mathematical terms to help Jinga with
                          this memory game?
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_3062"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_3061"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="56.84803001876173"
                      data-block-type="5"
                      id="block-7eb8c8c61fdda71f7b26"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3060"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                        >
                          <StaticImage
                            src="../../images/problemscape/pesky.png"
                            alt=""
                            height={300}
                            width={533}
                            quality={95}
                            objectFit="cover"
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "center",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_3081"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-ae0ff5772e53ebb454a2"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_3080"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                        >
                          <StaticImage
                            src="../../images/problemscape/paintball.png"
                            alt=""
                            height={300}
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Head() {
  return <Seo title="Ch 3 Overview" />;
}

export default ProblemScapeCh3Page;
